<template>
  <default-layout>
    <v-container fluid tag="section">
      <page-loading :show="isLoading" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <banner-list
        :items="banners"
        :meta="meta"
        :permissions="permissions"
        :bannerTypeFilters="bannerTypeFilters"
        :availabilityStatusFilters="availabilityStatusFilters"
        @changePage="changePage"
        @changeBannerTypeFilter="changeBannerTypeFilter"
        @changeAvailabilityStatusFilter="changeAvailabilityStatusFilter"
      ></banner-list>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const BannerList = () => import('@/components/banner-management/agent-banner/list');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    BannerList,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.banner.permissions,
      banners: (state) => state.banner.banners,
      meta: (state) => state.banner.meta,
      bannerTypeFilters: (state) => state.banner.bannerTypeFilters,
      availabilityStatusFilters: (state) => state.banner.availabilityStatusFilters,
    }),
  },
  async mounted() {
    try {
      this.isLoading = true;
      let query = this.$route.query;
      this.$store.commit('banner/RESET_DEFAULT');
      const response = await this.$store.dispatch('banner/getInitData', query);
      console.log('CHECK AGENT BANNER INIT DATA: ', response);
    } catch (e) {
      this.showAlert = true;
      this.messageAlert = e;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async loadData(query) {
      try {
        this.isLoading = true;
        const response = await this.$store.dispatch('banner/getInitData', query);
        console.log('GET AGENT BANNER DATA: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      } finally {
        this.isLoading = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    async changePage(page) {
      let query = this.$route.query;
      query.page = page;
      await this.loadData(query);
    },
    async changeBannerTypeFilter(val) {
      let query = this.$route.query;
      query.type_id = val;
      await this.loadData(query);
    },
    async changeAvailabilityStatusFilter(val) {
      let query = this.$route.query;
      query.availability_id = val;
      await this.loadData(query);
    },
  },
};
</script>
